export const BurgerMenu = () => {
  const burgerSelect = document.querySelector(
    ".burger-menu_nav__select .burger-menu_nav_item__arrow"
  );
  burgerSelect.addEventListener("click", () => {
    const selectContainer = burgerSelect.closest(".burger-menu_nav__select");
    const selectContent = selectContainer.querySelector(
      ".burger-menu_nav_sub-list"
    );
    if (selectContainer.classList.contains("open")) {
      selectContainer.classList.remove("open");
      selectContent.style.height = ``;
    } else {
      selectContainer.classList.add("open");
      const bodyElems = selectContent.children;
      let bodyWidth = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const bodyElem of bodyElems) {
        bodyWidth += bodyElem.offsetHeight;
      }
      selectContent.style.height = `${bodyWidth}px`;
    }
  });
  const burgerBtn = document.querySelector(".header-burger");
  const burgerMenu = document.querySelector(".burger-menu");
  burgerBtn.addEventListener("click", () => {
    burgerMenu.classList.toggle("show");
  });
};
