import {SwiperComponent} from "./SwiperComponent";
import {BurgerMenu} from "./components/BurgerMenu";
import {Modals} from "./components/Modals";
import {HeaderSubmenu} from "./components/HeaderSubmenu";
import {Switcher} from "./components/Switcher";
import {BankruptcySeeMoreBtn} from "./components/BankruptcySeeMoreBtn";
import {ReviewsSwiper} from "./components/ReviewsSwiper";
import {SwiperCurrentRates} from "./components/SwiperCurrentRates";
import {ArticlesSwiper} from "./components/ArticlesSwiper";
import {Form} from "./components/Form";
import {ServicesList} from "./components/ServicesList";
// Старарый функционал формы в блоке AnswerQuestions
// import {FormAnswerQuestions} from "./components/FormAnswerQuestions";
import {PlanBlock} from "./components/PlanBlock";
import {PlanModals} from "./components/PlanModals";
import {ReputationBlock} from "./components/ReputationBlock";
import {ConsequencesSwiper} from "./components/SwiperConsequences";
import {VideoModal} from "./components/VideoModal";

function main() {
  Form();
  SwiperComponent();
  BurgerMenu();
  Modals();
  HeaderSubmenu();
  Switcher();
  BankruptcySeeMoreBtn();
  ReviewsSwiper();
  ArticlesSwiper();
  ServicesList();
  SwiperCurrentRates();
  // Старарый функционал формы в блоке AnswerQuestions
  // FormAnswerQuestions();
  PlanBlock();
  PlanModals();
  ConsequencesSwiper();
  ReputationBlock();
  VideoModal();

  const inputs = document.querySelectorAll('input');
  inputs.forEach((inputsItem) => {
    inputsItem.addEventListener("change", () => {
      const inputContainer = inputsItem.closest(".input-container");
      if (inputContainer) {
        if (inputsItem.value !== "") {
          inputContainer.classList.add("filled");
        } else {
          inputContainer.classList.remove("filled");
        }
      }
    });
  });
};

document.addEventListener("DOMContentLoaded", main);
