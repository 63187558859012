import Swiper from 'swiper/bundle';

export const SwiperCurrentRates = () => {
  const swiper = new Swiper('.this-swiper.swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 3,
    scrollbar: {
      el: '.this-swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      992: {
        scrollbar: {
          el: '.this-swiper .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
      },
      0: {
        slidesPerView: 'auto',
      }
    },
  });

  const subMiddleContainers = document.querySelectorAll('.item__sub-middle-container-releative');

  function showHiddenContent(hiddenContent) {
    hiddenContent.style.display = 'block';
  }

  function hideHiddenContent(hiddenContent) {
    hiddenContent.style.display = 'none';
  }

  subMiddleContainers.forEach(container => {
    const questionIcon = container.querySelector('.question-icon');
    const hiddenContent = container.querySelector('.hidden-visible-content');

    questionIcon.addEventListener('mouseover', function() {
      showHiddenContent(hiddenContent);
    });

    questionIcon.addEventListener('mouseout', function() {
      hideHiddenContent(hiddenContent)
    });
  });

}
