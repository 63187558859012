import Swiper from 'swiper/bundle';

export const ServicesList = () => {
  const swiper = new Swiper('.services-list_container.swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 'auto',
    scrollbar: {
      el: '.services-list .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      1200: {
        scrollbar: {
          el: '.services-list .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
        navigation: {
          nextEl: '.services-list .arrow-right',
          prevEl: '.services-list .arrow-left',
        },
      },
    },
  });
}
