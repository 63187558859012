export const BankruptcySeeMoreBtn = () => {
  const seeMoreBtn = document.querySelector('.button-see-more');
  if (seeMoreBtn) {
    seeMoreBtn.addEventListener('click', function () {
      const wrapper = document.querySelector('.bankruptcy-wrapper');
      const bankruptcy = document.querySelector(".bankruptcy");
      if (seeMoreBtn.classList.contains("open")) {
        seeMoreBtn.innerHTML = "Показать все";
        seeMoreBtn.classList.remove("open");
        wrapper.classList.remove("open");
        bankruptcy.classList.remove("open");
        bankruptcy.style.position = '';
        document.querySelector(".bankruptcy-gradient").style.display = '';
      } else {
        seeMoreBtn.classList.add("open");
        wrapper.classList.add("open")
        bankruptcy.style.position = 'static';
        bankruptcy.classList.add("open");
        document.querySelector(".bankruptcy-gradient").style.display = 'none';
        seeMoreBtn.innerHTML = "Скрыть";
      }
    });
  }
}
