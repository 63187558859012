import Swiper from 'swiper/bundle';

export const ReviewsSwiper = () => {
  const swiper = new Swiper('.reviews .swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 3,
    scrollbar: {
      el: '.reviews .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      1200: {
        scrollbar: {
          el: '.reviews .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
        navigation: {
          nextEl: '.reviews .arrow-right',
          prevEl: '.reviews .arrow-left',
        },
      },
      992: {
        slidesPerView: 3,
      },
      556: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 'auto',
      },
    },
  });
}
