import IMask from "imask";

function checkValidate(form) {
  const formName = form.querySelector("[name='name']");
  let success = true;
  if (formName) {
    const inputContainer = formName.closest(".input-container");
    if (formName.value === "") {
      inputContainer.classList.add("error");
      success = success && false;
    } else {
      inputContainer.classList.remove("error");
      success = success && true;
    }
  }
  const formPhone = form.querySelector("[name='phone']");
  if (formPhone) {
    const inputContainer = formPhone.closest(".input-container");
    if (formPhone.value.length !== 18) {
      inputContainer.classList.add("error");
      success = success && false;
    } else {
      inputContainer.classList.remove("error");
      success = success && true;
    }
  }
  const formDept = form.querySelector("[name='debt']");
  if (formDept) {
    const inputContainer = formDept.closest(".input-container");
    if (inputContainer) {
      if (formDept.value === "") {
        inputContainer.classList.add("error");
        success = success && false;
      } else {
        inputContainer.classList.remove("error");
        success = success && true;
      }
    }
  }
  const formBox = form.querySelector("[name='politic']");
  if (formBox) {
    const inputContainer = formBox.closest(".consultation-modal_form_checkbox");
    if (!formBox.checked) {
      inputContainer.classList.add("error");
      success = success && false;
    } else {
      inputContainer.classList.remove("error");
      success = success && true;
    }
  }
  return success;
}

export const Form = () => {
  const phoneElements = document.querySelectorAll("[name='phone']");
  const modalSuccess = document.querySelector("#success-modal");
  const bodyBlock = document.querySelector("body");
  const modalContainer = document.querySelector(".modal.consultation-modal");

  const maskOptions = {
    mask: '+{7} (000) 000-00-00'
  };

  phoneElements.forEach((phoneElement) => {
    const mask = IMask(phoneElement, maskOptions);

  })

  const consultationForms = document.querySelectorAll(
    'form[data-form=\"consultation\"]'
  );
  const consultationFormsInputs = document.querySelectorAll(
    'form[data-form=\"consultation\"] input'
  );

  // eslint-disable-next-line no-unused-vars
  let lastForm = document.querySelector("form");

  consultationForms.forEach((consultationForm) => {
    consultationForm.addEventListener("submit", (e) => {
      e.preventDefault();
      if (checkValidate(consultationForm) && modalContainer) {
        modalSuccess.classList.add("show");
        bodyBlock.classList.add("hidden");
        modalContainer.classList.remove("show");
        lastForm = consultationForm;
        consultationFormsInputs.forEach((input) => {
          input.value = ""
          const inputContainer = input.closest(".input-container");
          if (inputContainer) {
            inputContainer.classList.remove("filled");
          }
        })
      }
      const data = new FormData(consultationForm);
      console.log(data);
    });
  })

  const moreForm = document.querySelector(
    ".consultation-modal_success_one-more"
  );
  moreForm.addEventListener("click", () => {
    modalSuccess.classList.remove("show");

    if (lastForm && lastForm.classList.contains("consultation-modal_form_form")) {
      modalContainer.classList.add("show");
    } else {
      bodyBlock.classList.remove("hidden");
      lastForm.scrollIntoView({block: "center"});
    }
  });

  const consultationBannerBtns = document.querySelectorAll("[data-consultation]");
  consultationBannerBtns.forEach((consultationBannerBtn) => {
    consultationBannerBtn.addEventListener("click", () => {
      const bodyBlock = document.querySelector("body");
      const consultationModal = document.querySelector("#consultation-id");
      if (consultationModal) {
        consultationModal.classList.add("show");
        bodyBlock.classList.add("hidden");
      }
    });
  });
};
