import Swiper from 'swiper/bundle';

export const ArticlesSwiper = () => {
  const swiper = new Swiper('.articles .swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 'auto',
    scrollbar: {
      el: '.articles .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      1200: {
        scrollbar: {
          el: '.articles .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
        navigation: {
          nextEl: '.articles .arrow-right',
          prevEl: '.articles .arrow-left',
        },
      },
    },
  });
}
