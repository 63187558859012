import Swiper from "swiper";

export const PlanBlock = () => {
  const swiper = new Swiper('.plan-block .swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 'auto',
    enabled: true,
    scrollbar: {
      el: '.plan-block .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      576: {
        enabled: false,
      },
    },
  });
  window.addEventListener('resize', function(event) {
    swiper.slideTo(0);
  }, true);
}
