import Swiper from 'swiper/bundle';

export const ConsequencesSwiper = () => {
  const swiper = new Swiper('.consequences .swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 'auto',
    scrollbar: {
      el: '.consequences .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      1200: {
        slidesPerView: 'auto',
      },
      600: {
        slidesPerView: 3,
      },
      0: {
        scrollbar: {
          el: '.consequences .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
        slidesPerView: 'auto',
      },
    },
  });
}
