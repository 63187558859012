export const Switcher = () => {
  const switchersBtns = document.querySelectorAll(".switcher .switcher_item");
  switchersBtns.forEach((switcher) => {
    switcher.addEventListener("click", () => {
      const switcherContainer = switcher.closest(".switcher");
      const activeElem = switcherContainer.querySelector(
        ".switcher_item.active"
      );
      activeElem.classList.remove("active");
      switcher.classList.add("active");
    });
  });
};
