function closeModal(elem) {
  const container = elem.closest(".modal");
  const bodyBlock = document.querySelector("body");
  if (container) {
    container.classList.remove("show");
    bodyBlock.classList.remove("hidden");
  }
}

export const Modals = () => {
  const modalsBgs = document.querySelectorAll(".modal-bg");
  modalsBgs.forEach((modalBg) => {
    modalBg.addEventListener("click", () => {
      closeModal(modalBg);
    });
  });

  const modalsClose = document.querySelectorAll(".modal-close");
  modalsClose.forEach((modalClose) => {
    modalClose.addEventListener("click", () => {
      closeModal(modalClose);
    });
  });
};
