import Swiper from "swiper";

export const ReputationBlock = () => {
  // const progressBlocks = document.querySelectorAll(".autoplay-progress");
  const swiper = new Swiper('.reputation-block_cards_container.swiper', {
    effect: "fade",
    grabCursor: true,
    rewind: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    on: {
      autoplayTimeLeft(s, time, progress) {
        let currentProgress = progress;
        const activeProgressBlock = document.querySelector(`[data-review].active`);
        if (activeProgressBlock.dataset.review != this.activeIndex) {
          activeProgressBlock.classList.remove("active");
        }
        const progressBlock = document.querySelector(`[data-review='${this.activeIndex}']`);
        progressBlock.classList.add("active");
        const progressCircle = progressBlock.querySelector(".autoplay-progress svg");
        if (currentProgress <= 0.01) {
          currentProgress = 1;
        }
        progressCircle.style.setProperty("--progress", 1 - currentProgress);
      }
    }
  });

  const reputationsNavigation = document.querySelectorAll("[data-review]");
  reputationsNavigation.forEach((reputationNav) => {
    reputationNav.addEventListener("click", () => {
      swiper.slideTo(reputationNav.dataset.review);
    })
  })
};
