export const PlanModals = () => {
  const stepsBtns = document.querySelectorAll("[data-step]");
  stepsBtns.forEach((stepBtn) => {
    stepBtn.addEventListener("click", () => {
      const stepModal = document.querySelector(`#${stepBtn.dataset.step}`);
      const bodyBlock = document.querySelector("body");
      if (stepModal) {
        stepModal.classList.add("show");
        bodyBlock.classList.add("hidden");
      }
    });
  });
};
