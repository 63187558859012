import Swiper from 'swiper/bundle';

export const SwiperComponent = () => {
  const swiper = new Swiper('.footer-swiper.swiper', {
    spaceBetween: 12,
    direction: 'horizontal',
    slidesPerView: 'auto',
    scrollbar: {
      el: '.footer-swiper .swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
        scrollbar: {
          el: '.footer-swiper .swiper-scrollbar',
          hide: false,
          draggable: true,
        },
      },
      768: {
        slidesPerView: 3,
      },
    },
  });
}
