export const VideoModal = () => {
  const videoBtns = document.querySelectorAll("button.is-video-review");
  videoBtns.forEach((videoBtn) => {
    videoBtn.addEventListener("click", () => {
      const bodyBlock = document.querySelector("body");
      const videoModal = document.querySelector(`#${videoBtn.dataset.video}`);
      if (videoModal) {
        videoModal.classList.add("show");
        bodyBlock.classList.add("hidden");
      }
    });
  });
};
